import { useTranslation } from 'react-i18next';
import { TextField } from 'enova-frontend-components';

import { useAdvancedSearchFormContext } from '../../types';

export const PoststedField = () => {
  const { t } = useTranslation();
  const { register } = useAdvancedSearchFormContext();

  const label = t(`search.advanced.field_poststed`);

  return (
    <TextField
      fullWidth
      disabled
      inputProps={{ 'aria-label': label }}
      label={label}
      title={label}
      {...register('poststed')}
    />
  );
};
