import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../hooks/useUser';
import { camelCasify } from '../../../utils/navigation';

import { TitleProps } from './tabMenuButton';
import { MenuItem, StyledNavLink } from './styledComponents';

export const DropDownMenuItem: FC<TitleProps> = ({ tab }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const buttonText = t(`mainScreen.tab.${camelCasify(tab)}`, {
    context: user?.type
  });

  return (
    <StyledNavLink to={tab} aria-label={buttonText}>
      {({ isActive }) => <MenuItem isActive={isActive}>{buttonText}</MenuItem>}
    </StyledNavLink>
  );
};
