import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { useAdvancedSearchFormContext } from '../../types';

import { NumberFieldWrapper } from './numberFieldWrapper';

export const SeksjonsnummerField = () => {
  const { t } = useTranslation();
  const { control } = useAdvancedSearchFormContext();

  const label = t(`search.advanced.field_seksjonsnummer`);

  return (
    <Controller
      control={control}
      name="seksjonsnummer"
      render={({ field, fieldState }) => {
        return (
          <NumberFieldWrapper
            error={!!fieldState?.error}
            fieldLabel={label}
            onValueChange={(e) => field.onChange(e.value)}
            {...field}
          />
        );
      }}
    />
  );
};
