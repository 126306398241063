import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Menu } from 'enova-frontend-components';
import React, { FC } from 'react';

import { camelCasify } from '../../../utils/navigation';
import { useUser } from '../../../hooks/useUser';

export const DropDownMenu: FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // Derive current page from URL path name
  const currentPage = t(
    `mainScreen.tab.${camelCasify(pathname.replace('/', ''))}`,
    {
      context: user?.type
    }
  );

  return (
    <Box mt={5} width="90%">
      <Menu>
        <Menu.Button dropdownIcon fullWidth variant="secondary">
          {currentPage}
        </Menu.Button>

        <Menu.Content>{children}</Menu.Content>
      </Menu>
    </Box>
  );
};
