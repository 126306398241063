import React, { FC, Fragment, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { camelCasify } from '../utils/navigation';
import i18n from '../i18n';
import { LoadingIndicator } from '../components/loadingIndicator';
import Layout from '../components/layout';

import { ProtectedRoute } from './protectedRoute';
import { BuildingRoutes } from './routes/buildingRoutes';
import { BuildingsTabContent } from './main/tabs/buildingsTab';
import { OtherBuildingsTabContent } from './main/tabs/otherBuildingsTab';
import { SearchTabContent } from './main/tabs/searchTab';
import { XmlTab } from './main/tabs/xmlTab';
import MainScreenLayout from './main';

const HomeScreen = lazy(() => import('./home'));
const LoginScreen = lazy(() => import('./login'));
const LogoutScreen = lazy(() => import('./logout'));
const ProfileScreen = lazy(() => import('./profile'));
const MainScreen = lazy(() => import('./main'));
const TracelogScreen = lazy(() => import('./tracelog'));
const DirectLogin = lazy(() => import('./directLogin'));
const PageNotFound = lazy(() => import('./pageNotFound'));
const StatisticsScreen = lazy(() => import('./statistics'));
const CreateBuilding = lazy(() => import('./createBuilding'));
const ExpertToolsXMLPortfolioScreen = lazy(
  () => import('./expertToolsXMLPortfolio')
);
const EnhetXmlRegistration = lazy(
  () => import('./main/xmlRegistrationView/xmlRegistration/enhet')
);
const FlerboligXmlRegistration = lazy(
  () => import('./main/xmlRegistrationView/xmlRegistration/flerbolig')
);

export const AppRoutes: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultTitle = [t('companyName'), t('appName')].join(' ');
  const documentTitle = pathname
    .split('/')
    .reverse()
    .reduce(
      (acc: string[], cur) => (cur ? [...acc, t(camelCasify(cur))] : acc),
      []
    )
    .join(' | ');

  return (
    <Fragment>
      <Helmet
        defaultTitle={defaultTitle}
        htmlAttributes={{ lang: i18n.language }}
        title={documentTitle}
        titleTemplate={`%s | ${defaultTitle}`}
      />
      <Layout fullWidth>
        <Suspense fallback={<LoadingIndicator text={t('loadingContent')} />}>
          <Routes>
            <Route index element={<HomeScreen />} />
            <Route path="login" element={<LoginScreen />} />
            <Route path="logout" element={<LogoutScreen />} />
            <Route path="direct-login" element={<DirectLogin />} />
            <Route path="statistikk" element={<StatisticsScreen />}>
              <Route path="*" />
            </Route>
            {/* Routes that require authentication */}
            <Route element={<ProtectedRoute />}>
              <Route path="bygg">
                <Route path="*" element={<BuildingRoutes />} />
              </Route>
              <Route path="profil" element={<ProfileScreen />} />
              <Route path="tracelog" element={<TracelogScreen />} />
              <Route path="opprett-bygg" element={<CreateBuilding />} />

              <Route element={<MainScreenLayout />}>
                <Route path="xml-oversikt" element={<XmlTab />} />
                <Route path="boliger" element={<BuildingsTabContent />} />
                <Route path="sok" element={<SearchTabContent />}>
                  <Route path="*" />
                </Route>
                <Route
                  path="andres-boliger"
                  element={<OtherBuildingsTabContent />}
                />
              </Route>

              <Route
                path="ekspertregistrering-portefolje/*"
                element={<ExpertToolsXMLPortfolioScreen />}
              />

              <Route path="xml-registrering">
                <Route
                  path="enhet/:step?/:registrationId?"
                  element={<EnhetXmlRegistration />}
                />
                <Route
                  path="flerbolig/:step?/:flerboligId?"
                  element={<FlerboligXmlRegistration />}
                />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </Layout>
    </Fragment>
  );
};
