import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import DismissableInfoBox from '../../../components/dismissableInfoBox';
import { useUser } from '../../../hooks/useUser';
import SearchView from '../buildings/components/searchView';

export const SearchTabContent = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <React.Fragment>
      <Helmet title={t(`mainScreen.tab.sok`, { context: user?.type })} />

      <DismissableInfoBox
        localStorageKey={user?.userId}
        title={t('mainScreen.findBuilding.info.title')}
      >
        {t('mainScreen.findBuilding.info.content')}
      </DismissableInfoBox>

      <SearchView />
    </React.Fragment>
  );
};
