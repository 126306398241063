import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

if (window.config.VITE_SENTRY_DSN) {
  // List of errors we don't want to pass to Sentry
  const errorsToExclude: string[] = [
    'tabsRef.current is null',
    "Cannot destructure property 'scrollTop' of 'Ee.current' as it is null.",
    'Right side of assignment cannot be destructured',
    'Ee.current is null'
  ];

  Sentry.init({
    dsn: window.config.VITE_SENTRY_DSN,
    normalizeDepth: 5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    sampleRate: 1,
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error instanceof Error && errorsToExclude.includes(error.message)) {
        return null;
      }

      return event;
    }
  });
}
