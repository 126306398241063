import {
  NumberField,
  NumberFieldProps,
  NumberFormatValues
} from 'enova-frontend-components';
import { FC } from 'react';

type Props = {
  fieldLabel: string;
};

export const NumberFieldWrapper: FC<NumberFieldProps & Props> = ({
  fieldLabel,
  error,
  onValueChange,
  value,
  allowLeadingZeros,
  isAllowed,
  ...rest
}) => {
  // Prevent insanely large numbers
  const defaultIsAllowed = ({ floatValue }: NumberFormatValues) =>
    !floatValue || floatValue < 1000000000;

  return (
    <NumberField
      fullWidth
      label={fieldLabel}
      inputProps={{
        'aria-label': fieldLabel
      }}
      autoComplete
      integer
      error={!!error}
      useThousandSeparator={false}
      allowLeadingZeros={!!allowLeadingZeros}
      title={fieldLabel}
      value={value ?? null}
      onValueChange={onValueChange}
      isAllowed={isAllowed || defaultIsAllowed}
      {...rest}
    />
  );
};
