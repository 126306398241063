import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  XMLParams,
  XmlRegistrationStep,
  XmlRegistrationType
} from '../../types';
import { FlerboligRegistrationXMLParams } from '../../flerbolig/types';

type CombinedXMLParams = XMLParams & FlerboligRegistrationXMLParams;

export const useXmlStepNavigation = () => {
  const { registrationId, flerboligId } = useParams<CombinedXMLParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToStep = (
    step: XmlRegistrationStep | null | undefined,
    type: XmlRegistrationType
  ) => {
    const id = type === 'flerbolig' ? flerboligId : registrationId;

    navigate(
      {
        pathname: `/xml-registrering/${type}/${step}/${id || ''}`,
        search: location.search
      },
      { state: location.state }
    );
  };

  return { navigateToStep };
};
