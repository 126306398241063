import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './userSlice';
import { registrationReducer } from './registration/registrationReducer';

/* ------------- Assemble The Reducers ------------- */
const rootReducer = combineReducers({
  register: registrationReducer,
  user: userReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
