import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

import { FlerboligbyggSummary } from '../../../../../services/types';

import { FlerboligRegistrationUnpublished } from './flerboligRegistrationUnpublished';

// This is a list of a users unpublished flerboligregistrerings.
export const UnpublishedFlerboligRegistrationList: FC<{
  flerboligbyggList?: FlerboligbyggSummary[];
}> = ({ flerboligbyggList }) => {
  const { t } = useTranslation();

  if (!flerboligbyggList || flerboligbyggList.length === 0) {
    return (
      <Typography>{t('xmlOverview.flerboligbygg.noRegistrations')}</Typography>
    );
  }

  return (
    <Fragment>
      {flerboligbyggList.map(flerboligbygg => (
        <FlerboligRegistrationUnpublished
          key={flerboligbygg.flerboligbyggId}
          flerboligbygg={flerboligbygg}
        />
      ))}
    </Fragment>
  );
};
