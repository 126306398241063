import { Box, Button, Paper, Typography } from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BuildingListEmptyState = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Paper outlined={true}>
      <Box padding={3}>
        <Typography gutterBottom={true} variant="h4">
          {t('buildingList.emptyState.title')}
        </Typography>
        <Typography>
          <Trans
            i18nKey={'buildingList.emptyState.description'}
            components={[<br key={0} />]}
          />
        </Typography>
        <Box marginTop={5}>
          <Button variant="secondary" onClick={() => navigate('../sok')}>
            {t('buildingList.emptyState.findBuilding')}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
