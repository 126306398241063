import React, { FC } from 'react';
import { BoxProps } from 'enova-frontend-components';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useUser } from '../../../hooks/useUser';
import {
  AppRoute,
  hasPermission,
  MainScreenTab
} from '../../../utils/navigation';

import { TabMenuButton } from './tabMenuButton';
import { DropDownMenu } from './dropDownMenu';
import { DropDownMenuItem } from './dropDownMenuItem';
import { TabMenu } from './styledComponents';

export const MainMenu: FC<BoxProps> = () => {
  const { xsScreen } = useMediaQuery();
  const { user } = useUser();
  const isExpertUser = hasPermission(AppRoute.EXPERT_TOOLS_XML, user);

  // Dropdown menu for small screens
  if (xsScreen) {
    return (
      <DropDownMenu>
        <DropDownMenuItem tab={MainScreenTab.BUILDINGS} />
        <DropDownMenuItem tab={MainScreenTab.OTHERS_BUILDINGS} />
        <DropDownMenuItem tab={MainScreenTab.SEARCH} />

        {/* This view is for expert users only */}
        {isExpertUser && <DropDownMenuItem tab={MainScreenTab.XML_OVERVIEW} />}
      </DropDownMenu>
    );
  }

  // Tab menu for larger screens
  return (
    <TabMenu mt={2}>
      <TabMenuButton tab={MainScreenTab.BUILDINGS} />
      <TabMenuButton tab={MainScreenTab.OTHERS_BUILDINGS} />
      <TabMenuButton tab={MainScreenTab.SEARCH} />

      {/* This view is for expert users only */}
      {isExpertUser && <TabMenuButton tab={MainScreenTab.XML_OVERVIEW} />}
    </TabMenu>
  );
};
