import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { useUser } from '../../../hooks/useUser';
import { camelCasify, MainScreenTab } from '../../../utils/navigation';

import { MenuButton, StyledNavLink } from './styledComponents';

export interface TitleProps {
  tab: MainScreenTab;
}

export const TabMenuButton: FC<TitleProps> = ({ tab }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const buttonText = t(`mainScreen.tab.${camelCasify(tab)}`, {
    context: user?.type
  });

  return (
    <StyledNavLink to={tab} aria-label={buttonText}>
      {({ isActive }) => (
        <MenuButton isActive={isActive}>
          <Typography variant="h2">{buttonText}</Typography>
        </MenuButton>
      )}
    </StyledNavLink>
  );
};
