import { Box, Button, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoute, hasPermission } from '../../../utils/navigation';
import { useUser } from '../../../hooks/useUser';

import { useXmlStepNavigation } from './xmlRegistration/common/hooks/useXmlStepNavigation';
import { UnpublishedRegistrationList } from './unpublishedRegistrationList';

export const XmlRegistrationView = () => {
  const { t } = useTranslation();
  const { navigateToStep } = useXmlStepNavigation();
  const { user } = useUser();

  if (!hasPermission(AppRoute.XML_REGISTRATION, user)) {
    return <Navigate to={`${AppRoute.HOME}`} replace />;
  }

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gap={4} mt={3}>
        <Typography variant="h3">{t('xmlOverview.header')}</Typography>
        <Typography variant="p">
          {t('energyAssessment.description_xml')}
        </Typography>
        <Box>
          <Button
            onClick={() => navigateToStep('velg-bygg', 'enhet')}
            aria-label={t('xmlOverview.startRegistration')}
          >
            {t('xmlOverview.startRegistration')}
          </Button>
        </Box>
      </Box>

      <UnpublishedRegistrationList />
    </Fragment>
  );
};
