import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

import { SimpleBuildingRegistration } from '../../../../../types/building';

import { EnhetRegistrationUnpublished } from './enhetRegistrationUnpublished';

export const UnpublishedEnhetRegistrationList: FC<{
  byggregistreringer?: SimpleBuildingRegistration[];
}> = ({ byggregistreringer }) => {
  const { t } = useTranslation();

  if (!byggregistreringer || byggregistreringer.length === 0) {
    return <Typography>{t('xmlOverview.enheter.noRegistrations')}</Typography>;
  }

  return (
    <Fragment>
      {byggregistreringer.map((registrering) => (
        <EnhetRegistrationUnpublished
          key={registrering.registreringId}
          registrering={registrering}
        />
      ))}
    </Fragment>
  );
};
