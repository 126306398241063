import React, { FC } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { FlerboligbyggSummary } from '../../../../../services/types';
import { SmallTypography } from '../../../buildings/utils';
import { useDeleteFlerboligRegistration } from '../hooks/useDeleteFlerboligRegistration';
import { getXMLFlerboligPath } from '../../../../../utils/navigation';

import { RegistrationListItemWrapper } from './registrationListItemWrapper';

// This component shows information about an unpublished flerboligregistrering.
export const FlerboligRegistrationUnpublished: FC<{
    flerboligbygg: FlerboligbyggSummary;
}> = ({flerboligbygg}) => {
  const { t } = useTranslation();

  const { isLoading, deleteFlerboligRegistration } =
    useDeleteFlerboligRegistration();

  const registrationPath = getXMLFlerboligPath({
    flerboligId: flerboligbygg.flerboligbyggId,
    step: 'oppsummering-av-fil'
  });

  const onDelete = () => {
    deleteFlerboligRegistration(flerboligbygg.flerboligbyggId);
  };

  return (
    <RegistrationListItemWrapper
      dateCreated={flerboligbygg.pabegyntDato}
      onDeleteRegistration={onDelete}
      registrationPath={registrationPath}
      isLoading={isLoading}
    >
      <SmallTypography variant="h4" component="p">
        {flerboligbygg.navn}
      </SmallTypography>
      <Typography>
        {t('xmlOverview.flerboligbygg.infoText', {
          count: flerboligbygg.antallRegistreringer
        })}
      </Typography>
    </RegistrationListItemWrapper>
  );
};
