import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { useAdvancedSearchFormContext } from '../../types';

import { NumberFieldWrapper } from './numberFieldWrapper';

export const PostnummerField = () => {
  const { t } = useTranslation();
  const label = t('search.advanced.field_postnummer');
  const {
    control,
    setValue,
    getFieldState,
    trigger,
    formState: { isSubmitted }
  } = useAdvancedSearchFormContext();

  const kommunenummer = useWatch({ control, name: 'kommunenummer' });

  useEffect(() => {
    if (isSubmitted) {
      trigger('kommunenummer');
    }
  }, [kommunenummer]);

  return (
    <Controller
      control={control}
      name="postnummer"
      render={({ field: { onChange, ...rest } }) => {
        return (
          <NumberFieldWrapper
            fieldLabel={label}
            error={!!getFieldState('kommunenummer').error}
            allowLeadingZeros
            onValueChange={(values, e) => {
              onChange(e.event);
              // Reset poststed and kommunenummer if postnummer length is not 4
              if (values.value.length != 4) {
                setValue('poststed', '');
                setValue('kommunenummer', '');
              }
            }}
            isAllowed={({ floatValue }) =>
              !floatValue || floatValue.toString().length <= 4
            }
            {...rest}
          />
        );
      }}
    />
  );
};
