import { Box, Typography } from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '../../../../components/loadingIndicator';
import { ErrorBox } from '../../../../components/errorBox';

import { UnpublishedEnhetRegistrationList } from './components/unpublishedEnhetRegistrationList';
import { UnpublishedFlerboligRegistrationList } from './components/unpublishedFlerboligRegistrationList';
import { useGetUserRegistrations } from './hooks/useGetUserRegistrations';

export const UnpublishedRegistrationList = () => {
  const { t } = useTranslation();
  const { isLoading, data, isError, refetch } = useGetUserRegistrations();

  if (isLoading) {
    return <LoadingIndicator text={t('xmlOverview.registrations.loading')} />;
  }

  if (isError) {
    return (
      <Box my={5}>
        <ErrorBox
          titleKey="xmlOverview.registrationsInProgress.error.title"
          descriptionKey="xmlOverview.registrationsInProgress.error.description"
          onRetry={refetch}
        />
      </Box>
    );
  }

  return (
    <Box mt={15} display="flex" flexDirection="column" gap={5}>
      <Typography variant="h3">
        {t('xmlOverview.registrationsInProgress')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4">{t('xmlOverview.enheter.header')}</Typography>
        <UnpublishedEnhetRegistrationList
          byggregistreringer={data?.enkeltregistreringer}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={5}>
        <Typography variant="h4">
          {t('xmlOverview.flerboligbygg.header')}
        </Typography>
        <UnpublishedFlerboligRegistrationList
          flerboligbyggList={data?.flerboligregistreringer}
        />
      </Box>
    </Box>
  );
};
