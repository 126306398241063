import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { InfoBox } from 'enova-frontend-components';

import DismissableInfoBox from '../../../components/dismissableInfoBox';
import BuildingFilterProvider from '../../../hooks/useBuildingFilter/provider';
import { useUser } from '../../../hooks/useUser';
import Buildings from '../buildings';
import { FilterCategory } from '../../../hooks/useBuildingFilter/context';
import { MainScreenTab } from '../../../utils/navigation';

import { useUserSelectedBuildingList } from './useUserSelectedBuildingList';

export const OtherBuildingsTabContent = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const pageSize = 200; // Page gets slow with very many buildings loaded, and backend will crash with much more than 2000 buildings.

  const { buildings, buildingsLoading, error, refreshBuildings } =
    useUserSelectedBuildingList(pageSize);

  const filterCategories = [
    FilterCategory.GEOGRAPHY,
    FilterCategory.BUILDING_CATEGORY
  ];

  return (
    <React.Fragment>
      <Helmet
        title={t(`mainScreen.tab.andresBoliger`, { context: user?.type })}
      />

      <DismissableInfoBox
        localStorageKey={user?.userId}
        title={t('mainScreen.otherBuildings.info.title')}
      >
        {t('mainScreen.otherBuildings.info.content')}
      </DismissableInfoBox>

      {buildings && buildings.length >= pageSize && (
        <InfoBox title={t('mainScreen.otherBuildings.overflow.title')}>
          {t('mainScreen.otherBuildings.overflow.content', { limit: pageSize })}
        </InfoBox>
      )}

      <BuildingFilterProvider
        allowFilter
        buildingList={{
          buildings,
          buildingsError: !!error,
          buildingsLoading,
          refreshBuildings
        }}
        categories={filterCategories}
      >
        <Buildings tab={MainScreenTab.OTHERS_BUILDINGS} />
      </BuildingFilterProvider>
    </React.Fragment>
  );
};
