import React from 'react';
import {
  Box,
  CONTENT_WIDTH,
  Divider,
  MainContentBox,
  styled
} from 'enova-frontend-components';
import { Outlet } from 'react-router-dom';

import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';

import { MainMenu } from './mainMenu';

const MainLayoutWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

const MainScreenLayout = () => (
  <MainLayoutWrapper>
    <MainMenu width={CONTENT_WIDTH} />

    <Box width="100%" display="block">
      <Divider />
    </Box>

    <MainContentBox pt={0}>
      <Outlet />
    </MainContentBox>

    <SessionExpiresDialog />
  </MainLayoutWrapper>
);

export default MainScreenLayout;
