import { useQuery } from 'react-query';
import { CancelToken } from 'apisauce';

import { queryKeys } from '../../../utils/react-query';
import { getUserSelectedBuildingList } from '../../../services/api';

export const useUserSelectedBuildingList = (pageSize: number) => {
  const { error, isLoading, data, refetch } = useQuery(
    [queryKeys.userSelectedBuildingList],
    ({ signal }) => {
      const source = CancelToken.source();
      const request = getUserSelectedBuildingList(source.token, pageSize);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query');
      });

      return request;
    },
    {
      keepPreviousData: true
    }
  );

  return {
    buildings: data,
    buildingsLoading: isLoading,
    error,
    refreshBuildings: refetch
  };
};
