import React, { FC } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { SimpleBuildingRegistration } from '../../../../../types/building';
import { SmallTypography } from '../../../buildings/utils';
import { useGetBuildingAddress } from '../../../../../hooks/useGetBuildingAddress';
import useBuildingIdentifier from '../../../../../hooks/useBuildingIdentifier';
import { useDeleteEnhetRegistration } from '../hooks/useDeleteEnhetRegistration';
import { getXmlEnhetPath } from '../../../../../utils/navigation';

import { RegistrationListItemWrapper } from './registrationListItemWrapper';

interface Props {
  registrering: SimpleBuildingRegistration;
}

export const EnhetRegistrationUnpublished: FC<Props> = ({ registrering }) => {
  const { t } = useTranslation();
  const { deleteEnhetRegistration, isLoading } = useDeleteEnhetRegistration();

  const { getBuildingAddress } = useGetBuildingAddress();
  const getBuildingIdentifier = useBuildingIdentifier();

  const eiendom = registrering.registreringEiendom;

  const onDelete = () => {
    deleteEnhetRegistration(registrering.registreringId);
  };

  const registrationPath = getXmlEnhetPath({
    registrationId: registrering.registreringId,
    step: 'oppsummering-av-fil'
  });

  return (
    <RegistrationListItemWrapper
      dateCreated={registrering.opprettetDato}
      onDeleteRegistration={onDelete}
      registrationPath={registrationPath}
      isLoading={isLoading}
    >
      <SmallTypography variant="h4" component="p">
        {getBuildingAddress(eiendom.adresse)}
      </SmallTypography>
      <Typography>
        {t('building.bruksenhetsnummer', {
          bruksEnhetsNummer: eiendom.bruksEnhetsNummer
        })}
      </Typography>
      <Typography>
        {getBuildingIdentifier({
          knr: eiendom.adresse.kommuneNummer,
          gnr: eiendom.gardsNummer,
          bnr: eiendom.bruksNummer,
          snr: eiendom.seksjonsNummer
        })}
      </Typography>
      <Typography>
        {`${t('expertToolsXML.review.adresseInfo.bygningsNr')}:  ${
          eiendom.bygningsNummer
        }`}
      </Typography>
    </RegistrationListItemWrapper>
  );
};
