import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../types/user';

const initialState: User = {} as User;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userUpdated(state, action: PayloadAction<User | undefined>) {
      return action.payload;
    }
  }
});

const { actions, reducer } = userSlice;

export const { userUpdated } = actions;

export default reducer;
