import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import AdvancedSearchView from './advancedSearch';
import SimpleSearch from './simpleSearch';

const SearchView: FC = () => (
  <Routes>
    <Route index element={<SimpleSearch />} />
    <Route path="advanced" element={<AdvancedSearchView />} />
  </Routes>
);

export default SearchView;
