import React, { FC, useEffect } from 'react';
import { Box } from 'enova-frontend-components';

import useBuildingFilter from '../../../../../hooks/useBuildingFilter';
import { MainScreenTab } from '../../../../../utils/navigation';

import BuildingListItem from './buildingListItem';
import { OrderBuildingListMenu } from './orderBuildingListMenu';
import {
  BuildingListError,
  BuildingListLoader,
  GridWrapper
} from './components';
import { BuildingListEmptyState } from './buildingListEmptyState';

interface Props {
  tab: MainScreenTab;
}

const BuildingList: FC<Props> = ({ tab }) => {
  const {
    buildingList,
    order: [sort, dir],
    updateOrder
  } = useBuildingFilter();

  useEffect(() => {
    buildingList?.refreshBuildings?.();
  }, [tab]);

  if (buildingList?.buildingsLoading) {
    return <BuildingListLoader />;
  }

  if (buildingList?.buildingsError) {
    return <BuildingListError />;
  }

  const buildings = buildingList?.buildings;

  if (!buildings || buildings.length === 0) {
    return <BuildingListEmptyState />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <GridWrapper>
        {buildings.length > 1 && (
          <OrderBuildingListMenu
            dir={dir}
            sort={sort}
            updateOrder={updateOrder}
          />
        )}

        {buildings?.map((building) => (
          <BuildingListItem
            key={building.energiObjektId}
            displayOptions={tab !== MainScreenTab.BUILDINGS}
            building={building}
          />
        ))}
      </GridWrapper>
    </Box>
  );
};

export default BuildingList;
