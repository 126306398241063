import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Typography } from 'enova-frontend-components';
import React, { FC, ReactNode } from 'react';

import useFormatDate from '../../../../../hooks/useFormatDate';
import RouterButtonLink from '../../../../../components/routerButtonLink';

import { DeleteRegistrationButton } from './deleteRegistrationButton';

interface Props {
  registrationPath: string;
  onDeleteRegistration: () => void;
  children: ReactNode;
  dateCreated?: Date;
  isLoading: boolean;
}

export const RegistrationListItemWrapper: FC<Props> = ({
  onDeleteRegistration,
  registrationPath,
  children,
  dateCreated,
  isLoading
}) => {
  const { t } = useTranslation();
  const { formatDate } = useFormatDate();

  return (
    <Paper shadow="small">
      <Box m={3}>
        <Typography bold>
          {t('energyrating.continue.existing.registration', {
            date: formatDate(dateCreated)
          })}
        </Typography>
      </Box>
      <Divider />
      <Box p={4} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
        <Box alignSelf="self-end">
          <Box display="flex" gap={2} flexWrap={'wrap'}>
            <RouterButtonLink
              to={registrationPath}
              variant="primary"
              size="medium"
            >
              {t('energyrating.continue.existing.continue')}
            </RouterButtonLink>
            <DeleteRegistrationButton
              isLoading={isLoading}
              onDelete={onDeleteRegistration}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
