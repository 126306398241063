import { FC } from 'react';
import { Button, Grid } from 'enova-frontend-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AdvancedSearchFormData, useAdvancedSearchFormContext } from '../types';
import { createSearchParams } from '../../utils';
import { useMediaQuery } from '../../../../../../../hooks/useMediaQuery';

import { KommuneField } from './components/kommuneField';
import { PoststedField } from './components/poststedField';
import { FestenummerField } from './components/festenummerField';
import { PostnummerField } from './components/postnummerField';
import { useAddressSearch } from './useAddressSearch';
import { FormErrors } from './components/formErrors';
import { SimpleSearchLink } from './components/simpleSearchLink';
import { BygningsnummerField } from './components/byggnummerField';
import { GardsnummerField } from './components/gardsnummerField';
import { BruksnummerField } from './components/bruksnummerField';
import { SeksjonsnummerField } from './components/seksjonsnummerField';

export const Form: FC = () => {
  const { pathname } = useLocation();
  const { xsScreen } = useMediaQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleSubmit, watch } = useAdvancedSearchFormContext();

  const postnummer = watch('postnummer');
  useAddressSearch(postnummer?.toString() ?? null);

  const handleSearch = (searchValues: AdvancedSearchFormData) => {
    navigate({
      pathname,
      search: createSearchParams(searchValues).toString()
    });
  };

  const onSubmit = handleSubmit((formData) => handleSearch(formData));

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid container spacing={3} item xs={7}>
          <Grid item xs={12} sm={4}>
            <PostnummerField />
          </Grid>
          <Grid item xs={12} sm={8}>
            <PoststedField />
          </Grid>
        </Grid>

        <Grid container spacing={3} item xs={7}>
          <Grid item xs={12} sm={12}>
            <KommuneField />
          </Grid>
        </Grid>

        <Grid container spacing={3} item xs={7}>
          <Grid item xs={12} sm={6}>
            <GardsnummerField />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BruksnummerField />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={7}>
          <SeksjonsnummerField />
        </Grid>

        <Grid item xs={12} sm={7}>
          <FestenummerField />
        </Grid>

        <Grid item xs={12} sm={7}>
          <BygningsnummerField />
        </Grid>
        <Grid item xs={12} sm={7}>
          <FormErrors />
        </Grid>

        <Grid item xs={12}>
          <SimpleSearchLink />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth={xsScreen}
            style={{ minWidth: '90px' }}
            type="submit"
          >
            {t('search.button')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
