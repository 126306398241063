import { Box, Menu, styled } from 'enova-frontend-components';
import React from 'react';
import { NavLink } from 'react-router-dom';

const TabMenu = styled(Box)`
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
  display: flex;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuButton = styled(({ isActive, ...props }) => <Box {...props} />)`
  background-color: transparent;
  color: black;
  border-radius: 5px;
  font-size: 2.25rem;
  font-weight: 600;
  text-transform: none;
  border: none;
  line-height: 1.4;
  padding: 15px;

  :hover {
    color: black;
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  h2 {
    line-height: 1.25;
    border-bottom: ${({ theme, isActive }) =>
      isActive ? `3px solid ${theme.palette.fjordGreen}` : ''};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuItem = styled(({ isActive, ...props }) => (
  <Menu.ListItem {...props} />
))<{
  isActive?: boolean;
}>`
  font-weight: 500;

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.greys.grey2 : ''};

  :hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;
`;

export { MenuItem, MenuButton, TabMenu, StyledNavLink };
