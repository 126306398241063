import React, { FC, useState } from 'react';
import { Box, FullScreenLoader, Grid, Hidden } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import RouterLink from '../../../../../../components/routerLink';
import { useSimpleSearch } from '../useSimpleSearch';
import BuildingFilterProvider from '../../../../../../hooks/useBuildingFilter/provider';

import { SmallScreenSearch } from './smallScreenSearch';
import { NormalScreenSearch } from './normalScreenSearch';
import { SearchResults } from './searchResults';

const SimpleSearchContent: FC = () => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = () => {
    navigate({
      search: new URLSearchParams({ q: searchString }).toString()
    });
  };

  const query = new URLSearchParams(location.search).get('q');

  // Search is used to manually trigger a new search request with the same
  // query if a previous request returned an error
  const { results, error, loading, search } = useSimpleSearch(query);

  if (loading) {
    return <FullScreenLoader open />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={6}>
        <Hidden smUp>
          <SmallScreenSearch
            disabled={!searchString}
            onSearch={handleSearch}
            searchString={searchString}
            setSearchString={setSearchString}
          />
        </Hidden>

        <Hidden smDown>
          <NormalScreenSearch
            onSearch={handleSearch}
            searchString={searchString}
            setSearchString={setSearchString}
          />
        </Hidden>

        <Box mt={2}>
          <RouterLink to="advanced">{t('search.advanced')}</RouterLink>
        </Box>
      </Grid>

      <SearchResults error={error} results={results} onSearch={search} />
    </Grid>
  );
};

const SimpleSearch: FC = () => {
  return (
    <BuildingFilterProvider allowSearch>
      <SimpleSearchContent />
    </BuildingFilterProvider>
  );
};

export default SimpleSearch;
