import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancer) =>
    getDefaultEnhancer().concat(Sentry.createReduxEnhancer())
});
export default store;
