import { Button, Dialog } from 'enova-frontend-components';
import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BuildingSearch } from '../../../../../../types/building';
import RouterButtonLink from '../../../../../../components/routerButtonLink';

interface AddBuildingProps {
  isOpen: boolean;
  queryParams?: Partial<BuildingSearch>;
  onCancel: () => void;
}

export const AddBuilding: FC<AddBuildingProps> = ({
  isOpen,
  onCancel,
  queryParams
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createBuildingUrl = new URLSearchParams();

  if (queryParams) {
    Object.entries(queryParams).map(([key, value]) =>
      createBuildingUrl.set(key, value.toString())
    );
  }

  return (
    <Fragment>
      <Dialog open={isOpen}>
        <Dialog.Title>
          {t('searchScreen.addBuilding.dialog.title')}
        </Dialog.Title>
        <Dialog.Content>
          {t('searchScreen.addBuilding.dialog.content')}
        </Dialog.Content>
        <Dialog.Actions>
          <Button variant="secondary" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <RouterButtonLink
            to={`/opprett-bygg?${createBuildingUrl.toString()}`}
          >
            {t('searchScreen.addBuilding.dialog.confirm')}
          </RouterButtonLink>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
